import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4392360a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fieldContent" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field", _ctx.classObject]),
    onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
    onTouchstart: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)), ["prevent"])),
    onMouseup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleRelease && _ctx.handleRelease(...args))),
    onTouchend: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleRelease && _ctx.handleRelease(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        innerHTML: _ctx.$t(`${_ctx.field.title}`)
      }, null, 8, _hoisted_2)
    ])
  ], 34))
}
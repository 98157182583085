
import { defineComponent } from 'vue';
import {
  TinySliderSettings,
  tns,
  tns as tns2,
  tns as tns3,
  tns as tns4,
} from 'tiny-slider';
import { veganArguments } from '../assets/arguments';

export default defineComponent({
  name: 'Info',
  data() {
    return {
      veganArguments,
    };
  },
  components: {},
  methods: {
    getColorCode(sliderCounter: number) {
      switch (sliderCounter) {
        case 1: {
          return 'D291BC';
        }
        case 2: {
          return 'C2AAA3';
        }
        case 3: {
          return '7B8FA5';
        }
        case 4: {
          return 'A3B79C';
        }
      }
    },
  },
  mounted() {
    let config: TinySliderSettings = {
      container: '.my-slider',
      loop: true,
      items: 1,
      slideBy: 'page',
      nav: false,
      autoplay: true,
      speed: 400,
      autoplayButtonOutput: false,
      mouseDrag: true,
      lazyload: true,
      arrowKeys: true,
      // gutter: 5,
      controlsContainer: '#customize-controls',
      responsive: {
        640: {
          items: 1,
        },

        768: {
          items: 1,
        },
      },
    };
    tns(config);

    config.container = '.my-slider2';
    config.controlsContainer = '#customize-controls2';
    config.autoplay = false;

    tns2(config);

    config.container = '.my-slider3';
    config.controlsContainer = '#customize-controls3';

    tns3(config);

    config.container = '.my-slider4';
    config.controlsContainer = '#customize-controls4';

    tns4(config);
  },
  computed: {},
});


import { defineComponent } from 'vue';
import Field from './Field.vue';
import ModalBox from './ModalBox.vue';
import ConfirmationPopup from './ConfirmationPopup.vue';
import Toast from './Toast.vue';
import { allFields, fieldType } from '../assets/data';
import { useDark } from '@vueuse/core';

export default defineComponent({
  name: 'Board',
  components: {
    Field,
    ModalBox,
    ConfirmationPopup,
    Toast,
  },
  data() {
    return {
      fadeIn: false,
      fadeOut: false,
      fields: [] as fieldType[],
      middleField: {
        id: 0,
        selected: false,
        title: '',
        selectable: false,
      } as fieldType,
      selectedField: {} as fieldType,
      bingo: false,
      rows: new Map(),
      columns: new Map(),
      ltrDiagonal: 0,
      rtlDiagonal: 0,
      tooSmall: false,
      boardKey: 0,
      showShufflePopup: false,
      showSharePopup: false,
      showToast: false,
      toastMessage: '',
      fieldPressedAt: new Date(),
      showInfoBox: false,
      isDark: useDark(),
    };
  },
  methods: {
    userShuffleFields() {
      this.shuffleFields();
      this.showShufflePopup = false;
      this.boardKey++;
    },
    shuffleFields() {
      this.fields = allFields.sort(() => 0.5 - Math.random()).slice(0, 24);
      this.fields.forEach((f) => (f.selected = false));
      this.resetBingo();
    },
    resetBingo() {
      this.fields = [
        ...this.fields.slice(0, 12),
        this.middleField,
        ...this.fields.slice(12),
      ];
      Array.from(Array(5)).forEach((x, i) => {
        this.rows.set(i, 0);
        this.columns.set(i, 0);
      });
      this.ltrDiagonal = 0;
      this.rtlDiagonal = 0;
      this.bingo = false;
    },
    handleSelection(field: fieldType) {
      this.fieldPressedAt = new Date();
      this.selectedField = field;
      this.showInfoBox = true;
    },
    handleMouseup(index: number) {
      let pressDuration = new Date().getTime() - this.fieldPressedAt.getTime();
      if (pressDuration < 500 && index >= 0) {
        this.selectedField.selected = !this.selectedField.selected;
        this.checkBingo(this.selectedField, index);
        this.showInfoBox = false;
      }
    },
    checkBingo(field: fieldType, index: number) {
      let column = index % 5;
      let row = Math.floor(index / 5);

      if (field.selected) {
        this.rows.set(row, this.rows.get(row) + 1);
        this.columns.set(column, this.columns.get(column) + 1);
        if (row == column) {
          ++this.ltrDiagonal;
        } else if (row + column == 4) {
          ++this.rtlDiagonal;
        }
      } else {
        this.rows.set(row, this.rows.get(row) - 1);
        this.columns.set(column, this.columns.get(column) - 1);
        if (row == column) {
          --this.ltrDiagonal;
        } else if (row + column == 4) {
          --this.rtlDiagonal;
        }
      }
      for (let i = 0; i < 5; i++) {
        if (
          this.rows.get(i) == 5 ||
          (i == 2 && this.rows.get(i) == 4) ||
          this.columns.get(i) == 5 ||
          (i == 2 && this.columns.get(i) == 4) ||
          this.ltrDiagonal == 4 ||
          this.rtlDiagonal == 4
        ) {
          this.bingo = true;
          return;
        }
        this.bingo = false;
      }
    },
    checkFieldSize() {
      let board: HTMLElement = this.$refs.board as HTMLElement;
      this.tooSmall =
        board.offsetWidth < 320 || window.innerHeight < 320 + 64 + 73 + 41;
    },
    initBoardFromUrl(): boolean {
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.has('fields')) {
        return false;
      }

      const fields = urlParams
        .get('fields')
        ?.split(',')
        .filter((s) => s)
        .map((s) => Number(s));
      if (
        !Array.isArray(fields) ||
        fields.length != 24 ||
        this.hasIllegalContent(fields, 1, allFields.length)
      ) {
        this.toast(this.$t('message.board.toast.initError'));
        return false;
      }

      fields.forEach((f) => {
        let tempField = allFields.find((af) => af.id == f);
        if (tempField) {
          this.fields.push(tempField);
        }
      });
      this.resetBingo();

      if (urlParams.has('selected')) {
        const selected = urlParams
          .get('selected')
          ?.split(',')
          .filter((s) => s)
          .map((s) => Number(s));
        if (
          !selected ||
          !Array.isArray(selected) ||
          selected.length < 0 ||
          selected.length >= 25 ||
          this.hasIllegalContent(selected, 0, 24)
        ) {
          this.toast(this.$t('message.board.toast.initError'));
          return false;
        }
        selected.forEach((i) => {
          this.fields[i].selected = true;
          this.checkBingo(this.fields[i], i);
        });
      }
      this.$emit('initialized');
      return true;
    },
    hasIllegalContent(
      array: number[],
      minNumber: number,
      maxNumber: number
    ): boolean {
      return array.some((element, index) => {
        return (
          array.indexOf(element) !== index ||
          element < minNumber ||
          element > maxNumber
        );
      });
    },
    copy() {
      this.showSharePopup = false;
      navigator.clipboard.writeText(this.getUrl);
      this.toast(this.$t('message.board.toast.urlCopied'));
    },
    copyFieldUrl() {
      this.showInfoBox = false;
      this.toast(this.$t('message.board.toast.urlCopied'));
    },
    toast(text: string) {
      this.toastMessage = text;
      if (!this.showToast) {
        setTimeout(() => (this.showToast = false), 3000);
      }
      this.showToast = true;
    },
    navigate() {
      this.showShufflePopup = false;
      window.location.hash = 'List';
    },
  },
  emits: {
    initialized: null,
  },
  mounted() {
    this.checkFieldSize();
    window.onresize = this.checkFieldSize;
    if (!this.initBoardFromUrl()) {
      this.shuffleFields();
    }
  },
  activated() {
    this.boardKey = 0;
    this.checkFieldSize();
  },
  computed: {
    getUrl(): string {
      let fields =
        'fields=' +
        this.fields
          .filter((f) => f.id > 0)
          .map((f) => f.id)
          .join(',');
      let selected =
        'selected=' +
        this.fields
          .map((f, i) => {
            return { f, i };
          })
          .filter((o) => o.f.selected)
          .map((o) => o.i)
          .join(',');
      let port = window.location.port;
      return (
        window.location.hostname +
        (port ? ':' + port : '') +
        '/?' +
        fields +
        '&' +
        selected
      );
    },
  },
});

import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4456f62b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "boardContainer" }
const _hoisted_2 = {
  key: 0,
  class: "confettiContainer"
}
const _hoisted_3 = {
  key: 0,
  class: "controls"
}
const _hoisted_4 = { class: "instructions" }
const _hoisted_5 = { class: "actions" }
const _hoisted_6 = ["title"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 0,
  class: "warning"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ModalBox = _resolveComponent("ModalBox")!
  const _component_ConfirmationPopup = _resolveComponent("ConfirmationPopup")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container", { dark: _ctx.isDark }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["title", { bingo: _ctx.bingo && !_ctx.tooSmall }])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass({ dark: _ctx.isDark })
        }, "B", 2),
        _createElementVNode("span", {
          class: _normalizeClass({ dark: _ctx.isDark })
        }, "I", 2),
        _createElementVNode("span", {
          class: _normalizeClass({ dark: _ctx.isDark })
        }, "N", 2),
        _createElementVNode("span", {
          class: _normalizeClass({ dark: _ctx.isDark })
        }, "G", 2),
        _createElementVNode("span", {
          class: _normalizeClass({ dark: _ctx.isDark })
        }, "O", 2)
      ], 2),
      (_openBlock(), _createElementBlock("div", {
        ref: "board",
        class: "board",
        key: _ctx.boardKey
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (f, index) => {
          return _withDirectives((_openBlock(), _createBlock(_component_Field, {
            class: _normalizeClass(["field", { active: _ctx.fadeIn && _ctx.selectedField == f }]),
            key: f.id,
            field: f,
            updateTrigger: _ctx.boardKey,
            onSelectField: _ctx.handleSelection,
            onRelease: ($event: any) => (_ctx.handleMouseup(index))
          }, null, 8, ["class", "field", "updateTrigger", "onSelectField", "onRelease"])), [
            [_vShow, !_ctx.tooSmall]
          ])
        }), 128)),
        (_ctx.bingo && !_ctx.tooSmall)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (n) => {
                return _createElementVNode("div", {
                  key: n,
                  class: "confetti"
                })
              }), 64))
            ]))
          : _createCommentVNode("", true)
      ])),
      (!_ctx.tooSmall)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", {
                class: _normalizeClass({ dark: _ctx.isDark })
              }, _toDisplayString(_ctx.$t('message.board.controls.clickToTick')), 3),
              _createElementVNode("span", {
                class: _normalizeClass({ dark: _ctx.isDark })
              }, _toDisplayString(_ctx.$t('message.board.controls.holdForInfo')), 3)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("i", {
                class: _normalizeClass(["fa fa-share-alt", { dark: _ctx.isDark }]),
                "aria-hidden": "true",
                title: _ctx.$t('message.board.popup.share.title'),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSharePopup = true))
              }, null, 10, _hoisted_6),
              _createElementVNode("button", {
                class: _normalizeClass(["shuffle", { dark: _ctx.isDark }]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showShufflePopup = true))
              }, _toDisplayString(_ctx.$t('message.board.controls.newBoard')), 3)
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.tooSmall)
        ? (_openBlock(), _createBlock(_component_ModalBox, {
            key: 1,
            field: _ctx.selectedField,
            show: _ctx.showInfoBox,
            onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showInfoBox = false)),
            onCopy: _ctx.copyFieldUrl
          }, null, 8, ["field", "show", "onCopy"]))
        : _createCommentVNode("", true),
      (!_ctx.tooSmall)
        ? (_openBlock(), _createBlock(_component_ConfirmationPopup, {
            key: 2,
            show: _ctx.showShufflePopup,
            confirmButtons: true,
            title: _ctx.$t('message.board.popup.shuffle.title'),
            onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showShufflePopup = false)),
            onConfirm: _ctx.userShuffleFields
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.board.popup.shuffle.text')), 1),
              _createElementVNode("a", {
                class: "popupTextLink",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args)))
              }, _toDisplayString(_ctx.$t('message.board.popup.shuffle.text2')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.board.popup.shuffle.text3')), 1)
            ]),
            _: 1
          }, 8, ["show", "title", "onConfirm"]))
        : _createCommentVNode("", true),
      (!_ctx.tooSmall)
        ? (_openBlock(), _createBlock(_component_ConfirmationPopup, {
            key: 3,
            show: _ctx.showSharePopup,
            confirmButtons: false,
            copyButton: true,
            title: _ctx.$t('message.board.popup.share.title'),
            onCancel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showSharePopup = false)),
            onCopy: _ctx.copy
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: _ctx.$t('message.board.popup.share.text')
              }, null, 8, _hoisted_7)
            ]),
            _: 1
          }, 8, ["show", "title", "onCopy"]))
        : _createCommentVNode("", true),
      (!_ctx.tooSmall)
        ? (_openBlock(), _createBlock(_component_Toast, {
            key: 4,
            text: _ctx.toastMessage,
            show: _ctx.showToast
          }, null, 8, ["text", "show"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.tooSmall)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('message.board.tooSmall')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["gradient", { dark: _ctx.isDark }])
    }, null, 2)
  ], 2))
}
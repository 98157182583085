import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Navbar, {
      currentNav: _ctx.navigation,
      class: "navbar",
      onNavigate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigate($event)))
    }, null, 8, ["currentNav"]),
    _createElementVNode("main", {
      class: _normalizeClass({ dark: _ctx.isDark })
    }, [
      (_openBlock(), _createBlock(_KeepAlive, { include: _ctx.include }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.navigation)))
      ], 1032, ["include"]))
    ], 2)
  ], 64))
}

import { defineComponent } from 'vue';
import $ from 'jquery';
import { getHash } from '@/util/url';
import { PropType } from 'vue';

export default defineComponent({
  name: 'SideNav',
  data() {
    return {};
  },
  props: {
    show: Boolean,
    titles: {
      type: Array as PropType<string[]>,
    },
  },
  methods: {
    doShow() {
      this.$emit('show', true);
      $('body').css('overflow', 'hidden');
    },
    doHide() {
      this.$emit('show', false);
      $('body').css('overflow', 'auto');
    },
    navigate(index: number, title: string) {
      this.doHide();
      window.location.hash = getHash(index + 1, title);
      this.$emit('scroll', index);
    },
  },
  emits: {
    show: null,
    scroll: null,
  },
  components: {},
});

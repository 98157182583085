
import { useDark } from '@vueuse/core';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConfirmationPopup',
  data() {
    return {
      isDark: useDark(),
    };
  },
  props: {
    title: String,
    text: String,
    show: Boolean,
    confirmButtons: Boolean,
    copyButton: Boolean,
  },
  emits: {
    cancel: null,
    confirm: null,
    copy: null,
  },
  watch: {
    show() {
      if (this.show) {
        (this.$refs.popupContainer as HTMLElement).focus();
      } else {
        (this.$refs.popupContainer as HTMLElement).blur();
      }
    },
  },
});

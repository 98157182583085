
import { defineComponent } from 'vue';
import Bingo from './components/Bingo.vue';
import Navbar from './components/Navbar.vue';
import List from './components/List.vue';
import Info from './components/Info.vue';
import About from './components/About.vue';
import WhyVegan from './components/WhyVegan.vue';
import Privacy from './components/Privacy.vue';
import { useDark } from '@vueuse/core';

export default defineComponent({
  name: 'App',
  data() {
    return {
      navigation: 'Bingo',
      include: ['Bingo'],
      isDark: useDark(),
    };
  },
  methods: {
    navigate(target: string) {
      this.navigation = target;
    },
  },
  components: {
    Navbar,
    Bingo,
    List,
    WhyVegan,
    Info,
    About,
    Privacy,
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-788e8a20"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "InfoContainer" }
const _hoisted_2 = { "data-aos": "fade-right" }
const _hoisted_3 = { class: "sectionText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h1", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.privacyPolicy.header')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.privacyPolicy.paragraph1')), 1),
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('message.privacyPolicy.header2')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.privacyPolicy.paragraph2')), 1),
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('message.privacyPolicy.header3')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.privacyPolicy.paragraph3')), 1),
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('message.privacyPolicy.header4')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.privacyPolicy.paragraph4')), 1),
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('message.privacyPolicy.header5')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.privacyPolicy.paragraph5')), 1)
      ])
    ])
  ]))
}

import { defineComponent, PropType } from "vue";
import { fieldType } from "../assets/data";
import { useDark } from "@vueuse/core";

export default defineComponent({
  name: "Field",
  props: {
    field: {
      type: Object as PropType<fieldType>,
    },
    updateTrigger: {
      type: Number,
    },
  },
  data() {
    return {
      isDark: useDark()
    };
  },
  methods: {
    handleClick() {
      if (this.field?.selectable) {
        this.$emit("selectField", this.field);
      }
    },
    handleRelease() {
      if (this.field?.selectable) {
        this.$emit("release");
      }
    },
  },
  emits: {
    selectField: null,
    release: null,
  },
  computed: {
    classObject() {
      return {
        selected: this.field ? this.field.selected : false,
        center: this.field ? this.field.id == 0 : false,
        nonCenter: this.field ? this.field.id != 0 : false,
        spin: this.updateTrigger ? this.updateTrigger > 0 : false,
        dark: this.field ? this.field.id != 0 && this.isDark : false
      };
    },
  },
});

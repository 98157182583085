
import { useDark } from '@vueuse/core';
import { defineComponent } from 'vue';
import { fieldType } from 'src/assets/data';
import { PropType } from 'vue';
import { getHash } from '@/util/url';

export default defineComponent({
  name: 'ModalBox',
  data() {
    return {
      isDark: useDark(),
    };
  },
  props: {
    field: Object as PropType<fieldType>,
    show: Boolean,
  },
  emit: {
    close: null,
    copy: null,
  },
  computed: {
    title() {
      return this.field && this.field.title
        ? this.$t(`${this.field.title}`).replace(/(<([^>]+)>)/gi, '')
        : '';
    },
  },
  methods: {
    copy() {
      if (this.field)
        navigator.clipboard.writeText(
          `${location.host}/${getHash(
            this.field?.id,
            this.$t(this.field?.title)
          )}`
        );
      this.$emit('copy');
    },
    stripText(title: string) {
      return title.replace(/(<([^>]+)>)/gi, '');
    },
  },
});


import { defineComponent } from 'vue';
import Board from './Board.vue'

export default defineComponent({
  name: 'Bingo',
  data() {
    return {
    }
  },
  components: {
    Board
  },
  methods: {
  },
  emits: {
    navigate: null,
  }
});

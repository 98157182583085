
import { defineComponent } from 'vue';
import Toast from './Toast.vue';
import { useDark } from '@vueuse/core';

export default defineComponent({
  name: 'Navbar',
  components: {
    Toast,
  },
  props: {
    header: String,
    currentNav: {
      type: String,
      default: 'Bingo',
    },
  },
  data() {
    return {
      expanded: false,
      hideNav: false,
      scrollBarWidth: 0,
      showToast: false,
      isDark: useDark(),
    };
  },
  methods: {
    getNavHeader() {
      switch (this.currentNav) {
        case 'Bingo':
          return this.$t('message.navBar.veganBingo');
        case 'List':
          return (
            this.$t('message.navBar.allQuotes') +
            this.$t('message.navBar.lastChar')
          );
        case 'WhyVegan':
          return this.$t('message.navBar.whyVegan') + '?';
        case 'Info':
          return this.$t('message.navBar.info') + 'o';
        case 'About':
          return this.$t('message.navBar.about') + 't';
        case 'Privacy':
          return (
            this.$t('message.navBar.privacy') +
            this.$t('message.navBar.privacyLastChar')
          );
        default:
          return '';
      }
    },
    getSubHeader() {
      switch (this.currentNav) {
        case 'Bingo':
          return this.$t('message.navBar.veganBingoSub');
        default:
          return this.$t('message.empty');
      }
    },
    changeLanguage() {
      this.$i18n.locale = this.$i18n.locale.startsWith('de') ? 'en' : 'de';
      if (!this.showToast) {
        setTimeout(() => (this.showToast = false), 3000);
      }
      this.showToast = true;
    },
    navigate(target: string) {
      window.location.hash = target;
    },
  },
  watch: {
    currentNav() {
      this.expanded = false;
      this.scrollBarWidth = 0;
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 1);
    },
  },
  emits: {
    navigate: null,
  },
  mounted() {
    let lastScrollPos = 0;
    let navbar: HTMLElement = this.$refs.nav as HTMLElement;

    let fn = () => {
      let currentScrollPos =
        window.pageYOffset || document.documentElement.scrollTop;
      let body = document.body;
      let html = document.documentElement;

      let docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      let winHeight = window.innerHeight;
      let navBarHeight = navbar.offsetHeight;

      if (
        lastScrollPos < currentScrollPos &&
        currentScrollPos > navBarHeight + navBarHeight
      ) {
        this.hideNav = !['About'].includes(this.currentNav);
        this.expanded = false;
      } else if (
        lastScrollPos > currentScrollPos &&
        !(currentScrollPos <= navBarHeight)
      ) {
        this.hideNav = false;
      }
      lastScrollPos = currentScrollPos;

      this.scrollBarWidth = (currentScrollPos / (docHeight - winHeight)) * 100;
    };

    window.addEventListener('scroll', fn);

    const hashNav = () => {
      const hash = new URL(location.href).hash.substring(1).split('?')[0];
      console.log(hash);
      if (
        ['Bingo', 'List', 'WhyVegan', 'Info', 'About', 'Privacy'].includes(hash)
      ) {
        this.$emit('navigate', hash);
      } else {
        window.location.hash = 'Bingo';
      }
    };
    hashNav();
    window.addEventListener('hashchange', hashNav);
  },
  computed: {
    getSelectedLang(): string {
      return this.$i18n.locale == 'de'
        ? this.$t('message.navBar.langChangedDe')
        : this.$t('message.navBar.langChangedEn');
    },
  },
});


import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Toast',
  props: {
    text: String,
    show: Boolean,
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-91804e64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bingo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Board = _resolveComponent("Board")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Board, {
      onNavigate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('navigate', $event)))
    })
  ]))
}